var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("center", [
    _c("h1", [_vm._v("Not Found!")]),
    _c(
      "p",
      [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Go home?")])],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }